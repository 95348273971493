import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import { useStockStatusMessage } from '@/talons/StockStatusMessage/useStockStatusMessage';
import Notification from '@/components/Notification';
import type { ConfigurableCartItem } from '@/types/product';
import { useFragment } from '@apollo/client';
import { ProductListingFragment } from '@/components/ProductListing/api/productListingFragments.gql';

interface IStockStatusMessage {
    message?: string;
    messageId?: string;
}

const StockStatusMessage = ({ messageId = 'message' }: IStockStatusMessage): ReactElement | null => {
    const { data: cartData } = useFragment<{ items: ConfigurableCartItem[] }>({
        fragment: ProductListingFragment,
        from: {
            __typename: 'Cart',
            id: 'Cart',
        },
    });

    const { hasOutOfStockItem } = useStockStatusMessage({ cartItems: cartData?.items as ConfigurableCartItem[] });
    const t = useTranslations('stockStatusMessage');

    if (!hasOutOfStockItem) return null;

    return <Notification variant="error">{t(messageId)}</Notification>;
};

export default StockStatusMessage;
